import { ReactElement, useState, useEffect } from 'react'
import classnames from 'classnames'

import css from './styles.css'

function toPercentage(value: number): number {
    if (value === 0) {
        return 0
    }

    if (value === 100) {
        return 100
    }
    return 5 + 0.4 * value
}

type Props = {
    className?: string
    loading?: boolean
    'aria-label': string
}

export function Progressbar(props: Props): ReactElement | null {
    const { loading = false, className, ...rest } = props
    const [value, setValue] = useState(0)

    useEffect(
        function () {
            if (!loading && value === 100) {
                const t = setTimeout(() => setValue(0), 300)
                return () => clearTimeout(t)
            }

            if (!loading && value > 0) {
                setValue(100)
                return () => undefined
            }

            if (!loading) {
                return () => undefined
            }

            if (value === 0) {
                setValue(1 + Math.random() * 2)
                return () => undefined
            }

            const t = setTimeout(
                () =>
                    setValue(function (value: number): number {
                        const res = value + 8 * value ** -0.5
                        return Math.min(99, res)
                    }),
                500 * Math.random() + 200,
            )
            return () => clearTimeout(t)
        },
        [loading, value],
    )

    return (
        <div
            className={classnames(css.wrapper, className)}
            role='progressbar'
            aria-valuenow={value}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-hidden={!loading}
            {...rest}
        >
            <div className={css.bar} style={{ transform: `scaleX(${toPercentage(value) / 100})` }} />
        </div>
    )
}
