import { ReactElement } from 'react'
import classnames from 'classnames'
import { t } from '@lingui/macro'
import { Close } from '../icon'
import { useLocalisation } from '../../i18n'

import css from './styles.css'

export type Props = {
    className?: string
    children: React.ReactNode
    label?: string
    disabled?: boolean
    onClick: () => void
}

export function Chip(props: Props): ReactElement {
    const { onClick, label, disabled, children, className, ...rest } = props
    const { i18n } = useLocalisation()

    return (
        <button
            className={classnames(css.button, className)}
            type='button'
            title={t(i18n)`Remove`}
            onClick={onClick}
            aria-label={label}
            disabled={disabled}
            {...rest}
        >
            <div className={css.label}>{children}</div>
            <div className={css.icon}>
                <Close aria-hidden='true' />
            </div>
        </button>
    )
}
